<template>
  <div id="report">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container fluid>
      <h1>Report</h1>
      <h4>{{ organisation.name }}</h4>
      <h4>{{ name }}</h4>
      <b-form-group label-cols="2" label-cols-lg="1" label="Filter" class="mb-0">
        <b-input-group>
          <b-form-input v-model="filter" placeholder="Type to Search" />
          <b-input-group-append>
            <b-btn :disabled="!filter" @click="filter = ''">Clear</b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-table class="mt-4" responsive :items="rows" :filter="filter"></b-table>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import ReportHelper from "@/helper/reporthelper";
import Console from "@/console";

export default {
  name: "report",
  created() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData"
  },
  data() {
    return {
      title: "Report",
      rows: [],
      organisation: {},
      fields: [{ key: "reportName", label: "Name" }],
      name: null,
      filter: null
    };
  },
  methods: {
    async fetchData() {
      const organisationId = this.$route.params.organisationId;
      const reportName = this.$route.params.reportname;

      this.getOrganisation(organisationId);
      this.getReport(organisationId, reportName);
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    async getOrganisation(organisationId) {
      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}organisations/${organisationId}`);

        Console.log(response.data);

        if (response.data.status === "success") {
          this.organisation = response.data.organisation;
        } else {
          this.showWarning("There was a problem getting the organisation.");
        }
      } catch {
        this.showWarning("There was a problem getting the organisation.");
      }
    },
    async getReport(organisationId, reportName) {
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}reports/${organisationId}/${reportName}`
        );

        if (response.data.status === "success") {
          this.rows = ReportHelper.getJsonReport(response.data);
          this.name = reportName;
        } else {
          this.showWarning("There was a problem getting report.");
        }
      } catch {
        this.showWarning("There was a problem getting report.");
      }
    }
  }
};
</script>