import FormatHelper from "@/helper/formathelper";
import Console from "../console";

class ReportHelper {
  static getJsonReport(data) {
    var xeroV1ReportIds = {};
    xeroV1ReportIds["ExecutiveSummary"] = true;
    xeroV1ReportIds["BankStatement"] = true;

    var xeroV2ReportIds = {};
    xeroV2ReportIds["ExecutiveSummary"] = true;

    var quickbooksOneLevelReportIds = {};
    quickbooksOneLevelReportIds["VendorExpenses"] = true;

    var quickbooksTwoLevelReportIds = {};
    quickbooksTwoLevelReportIds["GeneralLedger"] = true;

    if (data.report.reportID in xeroV1ReportIds) {
      return this.parseV1XeroReport(data);
    } else if (data.report.ReportID in xeroV2ReportIds) {
      return this.parseV2XeroReport(data);
    } else if (data.report.header.reportName in quickbooksOneLevelReportIds) {
      return this.parseQuickbooksOneLevelReport(data);
    } else if (data.report.header.reportName in quickbooksTwoLevelReportIds) {
      return this.parseQuickbooksTwoLevelReport(data);
    } else {
      Console.error("Report type not known");
    }
  }

  static parseQuickbooksOneLevelReport(data) {
    var rowItems = [];
    var rows = data.report.rows.row;
    var rowcount;
    for (rowcount = 0; rowcount < rows.length; rowcount++) {
      var columns = rows[rowcount].colData;
      var columncount;
      var rowItem = {};
      for (columncount = 0; columncount < columns.length; columncount++) {
        if (columncount == 0) {
          rowItem["Vendor"] = columns[columncount].value;
        } else if (columncount == 1) {
          rowItem["Spend"] = columns[columncount].value;
        } else {
          rowItem[columncount] = columns[columncount].value;
        }
      }
      rowItems.push(rowItem);
    }
    return rowItems;
  }

  static parseQuickbooksTwoLevelReport(data) {
    var rowItems = [];
    var rows = data.report.rows.row;

    const headings = data.report.columns.column;

    const headingLookup = [];
    for (let heading of headings) {
      headingLookup.push(heading.colTitle);
    }

    for (let rowcount = 0; rowcount < rows.length; rowcount++) {
      let rowsLevelTwo = rows[rowcount].rows.row;
      let section = rows[rowcount].header.colData[0].value;

      for (
        let rowcountLevelTwo = 1;
        rowcountLevelTwo < rowsLevelTwo.length;
        rowcountLevelTwo++
      ) {
        var columns = rowsLevelTwo[rowcountLevelTwo].colData;
        var columncount;
        let rowItem = {};
        for (columncount = 0; columncount < columns.length; columncount++) {
          rowItem["section"] = section;
          rowItem[headingLookup[columncount]] = columns[columncount].value;
        }

        if (ReportHelper.objectLength(rowItem) > 0) {
          rowItems.push(rowItem);
        }
      }
    }
    return rowItems;
  }

  static objectLength(obj) {
    let size = 0;

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        size++;
      }
    }

    return size;
  }

  static parseV1XeroReport(data) {
    Console.log("V1");
    var headersArray = data.report.rows.row[0].cells.cell;
    var headers = [];
    var i;
    for (i = 0; i < headersArray.length; i++) {
      headers.push(headersArray[i].value);

    }
    var k;
    var m;
    var rowItems = [];
    var sections = data.report.rows.row;
    var sectionCount;
    for (sectionCount = 1; sectionCount < sections.length; sectionCount++) {
      var rowArray = data.report.rows.row[sectionCount].rows.row;

      for (k = 0; k < rowArray.length; k++) {
        var rowItem = {};
        for (m = 0; m < i; m++) {
          rowItem[headers[m]] = rowArray[k].cells.cell[m].value;
          if (headers[m] === "Date") {
            Console.log(rowArray[k].cells.cell[m].value);
            rowItem[headers[m]] = FormatHelper.formatDateTimeToSec(
              rowArray[k].cells.cell[m].value
            );
          }
        }
        rowItems.push(rowItem);
      }
    }
    return rowItems;
  }

  static parseV2XeroReport(data) {
    var headersArray = data.report.Rows.find(obj => {
      return obj.RowType === "Header"
    })
    var headers = [];
    var i;
    for (i = 0; i < headersArray.Cells.length; i++) {
      if (headersArray.Cells[i].Value == "") {
        headers.push("Section");
      } else {
        headers.push(headersArray.Cells[i].Value);
      }
    }

    var k;
    var m;
    var rowItems = [];
    var sections = data.report.Rows.filter(obj => {
      return obj.RowType === "Section"
    })
    var sectionCount;
    for (sectionCount = 1; sectionCount < sections.length; sectionCount++) {

      var rowArray = sections[sectionCount].Rows;
      for (k = 0; k < rowArray.length; k++) {
        var rowItem = {};
        for (m = 0; m < i; m++) {
          rowItem[headers[m]] = rowArray[k].Cells[m].Value;
          if (headers[m] === "Date") {
            rowItem[headers[m]] = FormatHelper.formatDateTimeToSec(
              rowArray[k].Cells[m].Value
            );
          }
        }
        rowItems.push(rowItem);
      }
    }
    return rowItems;
  }

}

export default ReportHelper;
